/* eslint-disable @typescript-eslint/no-unsafe-call, react/function-component-definition */
import * as React from 'react';

import { castArray } from '@effable/misc';
import * as SliderPrimitive from '@radix-ui/react-slider';

import * as S from './slider.styled';

export interface SliderProps<V extends number | number[]>
  extends Omit<SliderPrimitive.SliderProps, 'value' | 'defaultValue' | 'onValueChange' | 'onValueCommit'> {
  value?: V;
  defaultValue?: V;
  onValueChange?: (value: V) => void;
  onValueCommit?: (value: V) => void;
}

export function Slider<V extends number | number[]>(props: SliderProps<V>): JSX.Element {
  const {
    value, defaultValue, onValueChange, onValueCommit, ...other
  } = props;

  const sliderValue = value ? (castArray(value) as number[]) : undefined;
  const sliderDefaultValue = defaultValue ? (castArray<V>(defaultValue) as number[]) : undefined;

  const onSliderValueChange = (values: number[]) => {
    onValueChange?.((values.length === 1 ? values[0] : values) as V);
  };

  const onSliderValueCommit = (values: number[]) => {
    onValueCommit?.((values.length === 1 ? values[0] : values) as V);
  };

  return (
    <S.SliderRoot
      value={sliderValue}
      defaultValue={sliderDefaultValue}
      onValueChange={onSliderValueChange}
      onValueCommit={onSliderValueCommit}
      {...other}
    >
      <S.SliderTrack>
        <S.SliderRange />
      </S.SliderTrack>
      <S.SliderThumb />
    </S.SliderRoot>
  );
}
