import * as React from 'react';

import NextLink from 'next/link';

import { capitalize } from '@effable/misc';
import {
  Badge, Box, Container, Heading, Link, Stack, Text,
} from '@effable/react';
import { PasswordForm } from '@/widgets/password-form';

import { MainLayout } from '@/layouts/main';

import { APP_TITLE, PageSEO } from '@/shared/lib/meta';
import { EnhancedNextPage } from '@/shared/types/enhanced-next-page';
import { OnlyBrowserPageProps } from '@/shared/types/only-browser-page-props';
import { SSGPageProps } from '@/shared/types/ssg-page-props';
import { SSRPageProps } from '@/shared/types/ssr-page-props';

type IndexPageProps = SSRPageProps & SSGPageProps<OnlyBrowserPageProps>;

const IndexPage: EnhancedNextPage<IndexPageProps> = (): JSX.Element => {
  return (
    <>
      <PageSEO description="Generate a random password in your browser" />

      <Box flex="1" display="flex" alignItems="center">
        <Container>
          <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <Box display="flex" flexDirection="column" alignItems="center" mb="15x">
              <Box display="flex" position="relative">
                <Heading>{capitalize(APP_TITLE)}</Heading>
                <Box position="absolute" top={0} left="100%">
                  <Badge color="accent">Beta</Badge>
                </Box>
              </Box>
              <Text textAlign="center" color="text.secondary">
                Generate strong and secure password&nbsp;in&nbsp;your&nbsp;browser
              </Text>
            </Box>

            <Box display="flex" flexDirection="column" maxWidth="420px" width="100%">
              <PasswordForm />
              <Box display="flex" justifyContent="center" mt="2x" px="3x">
                <Stack space="2x">
                  <Link href="/faq" color="inherit" component={NextLink}>
                    FAQ
                  </Link>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

IndexPage.Layout = MainLayout;

export default IndexPage;
