import * as React from 'react';

import { Box, Stack } from '@effable/react';

import { PasswordBar } from '@/features/password-bar';
import { PasswordGenerator } from '@/features/password-generator';

export const PasswordForm = () => {
  const [password, setPassword] = React.useState('');

  return (
    <Box
      backgroundColor="neutral.neutral3"
      borderRadius="2x"
      border="1px solid"
      borderColor="neutral.neutral7"
      overflow="hidden"
    >
      <PasswordBar value={password} />

      <PasswordGenerator onGenerate={setPassword} />
    </Box>
  );
};
