import * as React from 'react';

import {
  Box, Button, Checkbox, Stack, Text,
} from '@effable/react';

import { Slider } from '@/shared/components/slider';
import { Input } from '@/shared/components/system/input';

import { generatePassword } from './lib/generate-password';

export interface PasswordGeneratorProps {
  onGenerate: (value: string) => void;
}

export const PasswordGenerator = (props: PasswordGeneratorProps): JSX.Element => {
  const { onGenerate } = props;

  const [length, setLength] = React.useState(8);

  const [lowercase, setLowercase] = React.useState(true);
  const [uppercase, setUppercase] = React.useState(true);
  const [numbers, setNumbers] = React.useState(false);
  const [symbols, setSymbols] = React.useState(false);
  const [exclude, setExclude] = React.useState(false);
  const [excludeString, setExcludeString] = React.useState('');

  const canGenerate = lowercase || uppercase || numbers || symbols;

  const handleGenerate = () => {
    const password = generatePassword(length, {
      lowercase,
      uppercase,
      numbers,
      symbols,
      exclude: excludeString,
    });

    onGenerate(password);
  };

  return (
    <Box backgroundColor="neutral.neutral2" padding="4x">
      <Box mb="4x">
        <Box>
          <Box mb="1x" display="flex" justifyContent="space-between">
            <Text component="label">Password length</Text>

            <Text>{length}</Text>
          </Box>

          <Slider value={length} min={8} max={24} onValueChange={setLength} />
        </Box>
      </Box>

      <Stack direction="column" space="3x">
        <Checkbox checked={uppercase} onCheckedChange={(value) => setUppercase(!!value)}>
          Include Uppercase Letters
        </Checkbox>

        <Checkbox checked={lowercase} onCheckedChange={(value) => setLowercase(!!value)}>
          Include Lowercase Letters
        </Checkbox>

        <Checkbox checked={numbers} onCheckedChange={(value) => setNumbers(!!value)}>
          Include Numbers
        </Checkbox>

        <Checkbox checked={symbols} onCheckedChange={(value) => setSymbols(!!value)}>
          Include Symbols
        </Checkbox>

        <Box display="flex" flexDirection="column" width="100%">
          <Checkbox checked={exclude} onCheckedChange={(value) => setExclude(!!value)}>
            Exclude characters
          </Checkbox>

          {exclude && (
            <Box ml="8x" mt="3x">
              <Input
                placeholder="Type characters to exclude..."
                value={excludeString}
                onChange={(event) => setExcludeString(event.currentTarget.value)}
              />
            </Box>
          )}
        </Box>
      </Stack>

      <Box mt="4x" display="flex" flexDirection="column" width="100%">
        <Button disabled={!canGenerate} onClick={handleGenerate}>
          Generate Password
        </Button>
      </Box>
    </Box>
  );
};
