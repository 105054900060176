import { crandomInt, cshuffle } from '@effable/misc';

const lowercaseCharacters = 'abcdefghijklmnopqrstuvwxyz';
const uppercaseCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const numbersCharacters = '0123456789';
const symbolsCharacters = '!@#$%^&*()+_-=}{[]|:;"/?.><,`~';

const strictRules = [
  { name: 'lowercase', rule: /[a-z]/ },
  { name: 'uppercase', rule: /[A-Z]/ },
  { name: 'numbers', rule: /[0-9]/ },
  { name: 'symbols', rule: /[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]/ },
];

export interface PasswordOptions {
  /**
   * Include lowercase letters in password.
   *
   * If number is present, password will contain at least that number of characters.
   *
   * @default true
   */
  lowercase?: boolean | number;

  /**
   * Include uppercase letters in password.
   *
   * If number is present, password will contain at least that number of characters.
   *
   * @default true
   */
  uppercase?: boolean | number;

  /**
   * Include symbols in password.
   *
   * If number is present, password will contain at least that number of characters.
   *
   * @default false
   */
  symbols?: boolean | number;

  /**
   * Include numbers in password.
   *
   * If number is present, password will contain at least that number of characters.
   *
   * @default false
   */
  numbers?: boolean | number;

  /**
   * Characters to exclude from characters pool.
   *
   * @default ''
   */
  exclude?: string;
}

const validateConfiguration = (options: PasswordOptions, pool: string) => {
  const fitsRules = strictRules.reduce(
    (acc, rule) => {
      if (options[rule.name] === false) return acc;

      const isCorrect = rule.rule.test(pool);

      if (!isCorrect) {
        acc.errors.push(rule.name);
      }

      return acc;
    },
    { errors: [] } as { errors: string[] },
  );

  if (fitsRules.errors.length) {
    throw new Error(`Detected misconfiguration.
    You enabled ${fitsRules.errors.join(', ')} characters, but excluded all of it in the 'exclude' option`);
  }
};

/**
 * Generates password with the given options.
 */
export const generatePassword = (length: number, options: PasswordOptions = {}): string => {
  const {
    lowercase = true, uppercase = true, numbers = true, symbols = true, exclude,
  } = options;

  let lowercasePool = lowercaseCharacters;
  let uppercasePool = uppercaseCharacters;
  let numbersPool = numbersCharacters;
  let symbolsPool = symbolsCharacters;

  if (exclude) {
    exclude.split('').forEach((char) => {
      lowercasePool = lowercasePool.replace(char, '');
      uppercasePool = uppercasePool.replace(char, '');
      numbersPool = numbersPool.replace(char, '');
      symbolsPool = symbolsPool.replace(char, '');
    });
  }

  const pool = [lowercase && lowercasePool, uppercase && uppercasePool, numbers && numbersPool, symbols && symbolsPool]
    .filter((value): value is string => Boolean(value))
    .reduce((acc, value) => {
      // eslint-disable-next-line no-param-reassign
      acc += value;
      return acc;
    }, '');

  validateConfiguration(options, pool);

  const rules = [
    [+lowercase, lowercasePool],
    [+uppercase, uppercasePool],
    [+numbers, numbersPool],
    [+symbols, symbolsPool],
    [+length - +lowercase - +uppercase - +numbers - +symbols, pool],
  ] as const;

  let password = '';

  rules.forEach(([min, charPool]) => {
    if (min === 0 || charPool.length === 0) return;

    for (let i = 0; i < min; i += 1) {
      const charIndex = crandomInt(0, charPool.length - 1);
      password += charPool[charIndex];
    }
  });

  return cshuffle(password.split('')).join('');
};
