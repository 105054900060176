import * as React from 'react';

import {
  ActionButton, Box, Stack, Text,
} from '@effable/react';
import { RiCheckLine, RiDownloadLine, RiFileCopyLine } from 'react-icons/ri';

import { useClipboard } from '@/shared/hooks/use-clipboard';

import { download } from './lib/download-password';

export interface PasswordBarProps {
  value?: string;
}

export const PasswordBar = (props: PasswordBarProps): JSX.Element => {
  const { value } = props;

  const { onCopy, hasCopied } = useClipboard(value ?? '');

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      backgroundColor="neutral.neutral3"
      padding="4x"
      minHeight="64px"
    >
      <Text variant="l" color={value ? 'text.primary' : 'text.disabled'}>
        {value || '#########'}
      </Text>

      <Box display="flex" position="relative" ml="1x">
        <Stack space="1x">
          <ActionButton
            onClick={() => download(value)}
            label="Download password"
            title="Download password"
            size="large"
            color="neutral"
            disabled={!value}
          >
            <RiDownloadLine />
          </ActionButton>

          <ActionButton
            onClick={onCopy}
            disabled={!value}
            label="Copy generated password"
            title="Copy generated password"
            size="large"
            color={hasCopied ? 'success' : 'neutral'}
          >
            {hasCopied ? <RiCheckLine /> : <RiFileCopyLine />}
          </ActionButton>
        </Stack>
      </Box>
    </Box>
  );
};
