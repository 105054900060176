import { createTransition } from '@effable/react';
import styled from '@emotion/styled';
import * as Slider from '@radix-ui/react-slider';

const sizeTrack = {
  small: 12,
  medium: 16,
  large: 20,
};

export const SliderRoot = styled(Slider.Root)((props) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  touchAction: 'none',
  height: 12,
  cursor: 'pointer',
}));

export const SliderTrack = styled(Slider.Track)((props) => ({
  backgroundColor: props.theme.colors.neutral.neutralA5,
  position: 'relative',
  flexGrow: 1,
  borderRadius: '2px',
  height: 6,
}));

export const SliderRange = styled(Slider.Range)((props) => ({
  position: 'absolute',
  backgroundColor: props.theme.colors.accent.accent9,
  borderRadius: props.theme.radii.round,
  height: '100%',
}));

export const SliderThumb = styled(Slider.Thumb)((props) => ({
  display: 'block',
  width: 12,
  height: 12,
  backgroundColor: props.theme.colors.accent.accent9,
  border: '4px solid white',
  boxShadow: `0 1px 10px ${props.theme.colors.neutral.neutralA7}`,
  borderRadius: props.theme.radii.round,
  transition: createTransition(['transform', 'box-shadow'], { duration: 225 }),
  '&:active': { transform: 'scale(1.15)' },
  '&:focus': { outline: 'none', boxShadow: `0 0 0 2px ${props.theme.colors.accent.accentA7}` },
}));
