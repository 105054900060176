export const download = (value?: string) => {
  if (!value) return;

  const saveName = 'passkip.txt';

  const hiddenElement = document.createElement('a');

  hiddenElement.href = `data:attachment/text,${encodeURIComponent(`Generated Password from Passkip:\n${value}`)}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = saveName;
  hiddenElement.click();
};
